import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './MarketNewsDetails.module.scss'
import {
  TAG_COLOR_MAPPING,
  EMAIL_NEWSLETTER_MAPPING,
  NewsTags,
  INewsfeedItemData,
  FundraiseRounds,
  TAG_OPTIONS,
  INewsfeed,
} from '@common/interfaces/newsfeed'
import { ReactComponent as Square } from '@assets/images/square.svg'
import { ReactComponent as EditIcon } from '@assets/images/edit-outlined-icon.svg'
import { ReactComponent as DeleteIcon } from '@assets/images/delete-icon.svg'
import genericSs from '@styles/generic.module.scss'
import TextField from '../Common/TextField'
import { formatDateCalendarNoTime, formatPrice } from '../../helpers/helpers'
import Button from '../Common/Button'
import ModalButton from '../Common/Button/Button'
import { Box, InputLabel, Tooltip } from '@mui/material'
import { Field, Form } from 'react-final-form'
import CurrencyField from '../Common/CurrencyField'
import LinkedAccountItem from './LinkedAccountItem'
import { stringAvatar } from '../Notes/helpers/helpers'
import Modal from '../Common/Modal'
import { ILoadingData } from '../../redux/types'
import TableLoader from '../Common/TableLoader'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import cn from 'classnames'
import SelectField from '../Common/SelectField'
import UpdateBrandImageModal from '../UpdateBrandImageModal'
import CreatableSelectField from '../Common/CreatableSelectField'
import { ReactComponent as CloseChip } from '@assets/images/close-chip.svg'
import { ReactComponent as PlusCircleIcon } from '@assets/images/plus-circle-icon.svg'
import LinkedAccountInput from './LinkedAccountInput'
import WarningModal from '../WarningModal'

const validate = makeValidate(
  Yup.object().shape({
    salesforceLink: Yup.string()
      .typeError('Paste a valid URL')
      .required('Paste a valid URL')
      .url('Paste a valid URL')
      .matches(/(\/(Account|Lead))/, 'Paste a valid URL'),
  }),
)

interface IProps {
  newsItemId: string
  updateNewsfeed: (id: string, params: object) => Promise<any>
  showNewsfeed: (id: string, params?: object) => void
  newsfeedItem: ILoadingData<INewsfeedItemData>
  listBrandOptions: (params: object) => Promise<any>
  deleteNews: (id: string) => Promise<any>
  setCurrentNewsItem: (item: INewsfeed | null) => void
}

const MarketNewsDetails = ({
  newsItemId,
  newsfeedItem,
  updateNewsfeed,
  showNewsfeed,
  listBrandOptions,
  deleteNews,
  setCurrentNewsItem,
}: IProps) => {
  const [editingStates, setEditingStates] = useState({
    headline: false,
    source: false,
    fundraise: false,
    linkedAccounts: false,
  })
  const [modalStates, setModalStates] = useState({
    isEditProfilePicModalOpen: false,
    isNewBrandModalOpen: false,
    isDeleteModalOpen: false,
  })

  const [isSaving, setIsSaving] = useState(false)
  const [newBrandError, setNewBrandError] = useState(null)
  const [isAddTagShown, setIsAddTagShown] = useState(false)

  const { data: newsItem, isLoading } = useMemo(
    () => ({
      data: newsfeedItem?.data?.data,
      isLoading: newsfeedItem?.isLoading,
    }),
    [newsfeedItem],
  )

  useEffect(() => {
    if (newsItemId) {
      showNewsfeed(newsItemId)
      setIsAddTagShown(false)
      setEditingStates({
        headline: false,
        source: false,
        fundraise: false,
        linkedAccounts: false,
      })
    }
  }, [newsItemId, showNewsfeed])

  const toggleEditingState = useCallback((key) => {
    setEditingStates((prev) => ({ ...prev, [key]: !prev[key] }))
  }, [])

  const handleSaveLinkedAccounts = useCallback(
    async (formValues) => {
      const { brandOptionValue = null } = formValues
      await updateNewsfeed(newsItemId, {
        salesforceId: brandOptionValue?.value || null,
      })
      await showNewsfeed(newsItemId, { skipLoader: true })

      toggleEditingState('linkedAccounts')
    },
    [toggleEditingState, updateNewsfeed, showNewsfeed, newsItemId],
  )

  const handleSaveHeadline = useCallback(
    async (formValues) => {
      const { headline, headlineSummary = null } = formValues
      await updateNewsfeed(newsItemId, { headline, headlineSummary })
      await showNewsfeed(newsItemId, { skipLoader: true })

      toggleEditingState('headline')
    },
    [toggleEditingState, updateNewsfeed, showNewsfeed, newsItemId],
  )

  const loadOptions = useCallback(
    async (search: string) => {
      const res = await listBrandOptions({ search })
      return res.data
    },
    [listBrandOptions],
  )
  const loadBrandOptions = useCallback(async (search: string) => loadOptions(search), [loadOptions])

  const toggleFundraiseEditing = useCallback(() => {
    toggleEditingState('fundraise')
  }, [toggleEditingState])

  const toggleSourceEditing = useCallback(() => {
    toggleEditingState('source')
  }, [toggleEditingState])

  const handleSaveFundraise = useCallback(
    async (formValues) => {
      const { investor = null, amount = null, round = null } = formValues
      await updateNewsfeed(newsItemId, { investor, amount, round })
      toggleFundraiseEditing()
      await showNewsfeed(newsItemId, { skipLoader: true })
    },
    [toggleFundraiseEditing, updateNewsfeed, showNewsfeed, newsItemId],
  )

  const handleSaveSource = useCallback(
    async (formValues) => {
      const { link = null } = formValues

      await updateNewsfeed(newsItemId, { link })
      toggleSourceEditing()
      await showNewsfeed(newsItemId, { skipLoader: true })
    },
    [updateNewsfeed, showNewsfeed, toggleSourceEditing, newsItemId],
  )

  const toggleShowAddTag = useCallback(() => {
    setIsAddTagShown((prev) => !prev)
  }, [setIsAddTagShown])

  const initials = useMemo(() => {
    const name = newsItem?.brandFromSalesforce?.brandName || ''
    return name.split(' ').length > 1 ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : name[0]
  }, [newsItem])

  const handleOpenNewBrandModal = useCallback(() => {
    setModalStates((prevState) => ({
      ...prevState,
      isNewBrandModalOpen: true,
    }))
  }, [])

  const handleCloseNewBrandModal = useCallback(() => {
    setModalStates((prevState) => ({
      ...prevState,
      isNewBrandModalOpen: false,
    }))
    setIsSaving(false)
  }, [])

  const handleToggleDeleteModal = useCallback(() => {
    setModalStates((prevState) => ({
      ...prevState,
      isDeleteModalOpen: !prevState.isDeleteModalOpen,
    }))
  }, [])

  const handleDeleteNewsConfirm = useCallback(async () => {
    setIsSaving(true)

    await deleteNews(newsItemId)
    handleToggleDeleteModal()
    setIsSaving(false)
    setCurrentNewsItem(null)
  }, [deleteNews, newsItemId, handleToggleDeleteModal, setCurrentNewsItem])

  const openEditProfilePicModal = useCallback(() => {
    setModalStates((prevState) => ({
      ...prevState,
      isEditProfilePicModalOpen: true,
    }))
  }, [])

  const handleCreateNewBrand = useCallback(
    async (formValues) => {
      const { salesforceLink = null } = formValues
      setIsSaving(true)
      const result = await updateNewsfeed(newsItemId, { salesforceLink })
      if (!!result?.data?.error) {
        setIsSaving(false)
        setNewBrandError('Brand either exists or URL is invalid')
      } else {
        handleCloseNewBrandModal()
        await showNewsfeed(newsItemId, { skipLoader: true })

        setNewBrandError(null)
      }
    },
    [handleCloseNewBrandModal, updateNewsfeed, newsItemId, showNewsfeed],
  )

  const handleDeleteTag = useCallback(async () => {
    await updateNewsfeed(newsItemId, { type: null })
    await showNewsfeed(newsItemId, { skipLoader: true })
  }, [showNewsfeed, newsItemId, updateNewsfeed])

  const handleAddTag = useCallback(
    ({ target: { value } }) => {
      updateNewsfeed(newsItemId, { type: value })
      toggleShowAddTag()
      showNewsfeed(newsItemId, { skipLoader: true })
    },
    [updateNewsfeed, showNewsfeed, newsItemId, toggleShowAddTag],
  )

  const investors = useMemo(() => {
    return newsItem?.linkedAccounts?.filter((item) => item.type === NewsTags.Fundraise)
  }, [newsItem])

  const handleAddNewOtherAccount = useCallback(
    async (newValue, isFundraise) => {
      const { isNew, value } = newValue[newValue.length - 1]
      if (isNew) {
        handleOpenNewBrandModal()
      } else {
        await updateNewsfeed(newsItemId, {
          otherAccountId: value || null,
          isFundraise: isFundraise,
        })
        await showNewsfeed(newsItemId, { skipLoader: true })
      }
    },
    [handleOpenNewBrandModal, updateNewsfeed, newsItemId, showNewsfeed],
  )

  const handleDeleteLinkedAccount = useCallback(
    async (linkedAccountId) => {
      await updateNewsfeed(newsItemId, {
        linkedAccountId,
      })
      await showNewsfeed(newsItemId, { skipLoader: true })
    },
    [updateNewsfeed, newsItemId, showNewsfeed],
  )

  return !isLoading && newsItem ? (
    <div className={styles.detailsContainer}>
      <div className={styles.header}>
        <Form
          onSubmit={handleSaveHeadline}
          initialValues={newsItem}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className={styles.headerContainer}>
                  {newsItem?.brandFromSalesforce?.id ? (
                    <div
                      className={styles.avatar}
                      {...stringAvatar(newsItem?.brandFromSalesforce?.brandName)}
                    >
                      {newsItem?.brandFromSalesforce.brandIcon ? (
                        <div className={styles.avatarContainer}>
                          <img
                            className={styles.avatarImg}
                            src={newsItem?.brandFromSalesforce.brandIcon}
                            alt="logo"
                          />
                        </div>
                      ) : (
                        <div className={styles.avatarText}>{initials}</div>
                      )}
                      <EditIcon className={styles.editIcon} onClick={openEditProfilePicModal} />
                    </div>
                  ) : (
                    <Tooltip title={'Brand is required to update the image.'} placement="top">
                      <Square className={styles.avatarImg} />
                    </Tooltip>
                  )}
                  <div className={styles.titleContainer}>
                    <div className={styles.titleRow}>
                      <div className={styles.title}>
                        {editingStates.headline ? (
                          <Field
                            name="headline"
                            render={({ input }) => (
                              <TextField {...input} className={styles.titleEditing} multiline />
                            )}
                          />
                        ) : (
                          newsItem?.headline
                        )}
                      </div>
                      {!editingStates.headline ? (
                        <div className={styles.iconContainer}>
                          <EditIcon
                            className={styles.editFieldIcon}
                            onClick={() => toggleEditingState('headline')}
                          />
                          <DeleteIcon
                            className={styles.deleteIcon}
                            onClick={handleToggleDeleteModal}
                          />
                        </div>
                      ) : (
                        <Button onClick={handleSubmit}>Save</Button>
                      )}
                    </div>
                    <div className={styles.titleRow}>
                      <div className={styles.tagsContainer}>
                        {newsItem.type && (
                          <div className={styles.typeTagContainer}>
                            <div
                              style={TAG_COLOR_MAPPING[newsItem.type]}
                              className={cn(styles.tags, styles.typeTag)}
                            >
                              {newsItem.type}
                            </div>
                            <CloseChip onClick={handleDeleteTag} className={styles.tagClose} />
                          </div>
                        )}
                        {newsItem.isClient && (
                          <div style={TAG_COLOR_MAPPING[NewsTags.Client]} className={styles.tags}>
                            {NewsTags.Client}
                          </div>
                        )}
                        {newsItem.isProspect && (
                          <div style={TAG_COLOR_MAPPING[NewsTags.Prospect]} className={styles.tags}>
                            {NewsTags.Prospect}
                          </div>
                        )}
                        <div className={styles.tags}>
                          {isAddTagShown && (
                            <SelectField
                              name="type"
                              className={cn(styles.select, {
                                [styles.fieldOpen]: isAddTagShown,
                              })}
                              variant="outlined"
                              withClear
                              options={TAG_OPTIONS}
                              useFinalForm={false}
                              onChange={handleAddTag}
                            />
                          )}
                          {!newsItem.type && !isAddTagShown && (
                            <div className={styles.addTags} onClick={toggleShowAddTag}>
                              <PlusCircleIcon className={styles.circleIcon} />
                              <div className={styles.addTagsText}>Add tag</div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={styles.date}>
                        {formatDateCalendarNoTime(newsItem.createdAt)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.headlineSummary}>
                  {editingStates.headline ? (
                    <Field
                      name="headlineSummary"
                      render={({ input }) => (
                        <TextField
                          {...input}
                          placeholder="Add Summary"
                          className={styles.titleEditing}
                          multiline
                        />
                      )}
                    />
                  ) : (
                    newsItem?.headlineSummary
                  )}
                </div>
              </form>
            )
          }}
        />
      </div>

      <div className={styles.body}>
        <div className={styles.bodyContainer}>
          <Form
            onSubmit={handleSaveLinkedAccounts}
            initialValues={newsItem}
            render={({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Box className={styles.fieldsContainer}>
                    <div className={styles.bodyTitle}>
                      <div className={styles.bodyTitleName}>Linked Accounts</div>
                      <div className={styles.bodyTitleEdit}>
                        {editingStates.linkedAccounts ? (
                          <Button color="primary" variant="contained" small type="submit">
                            Save
                          </Button>
                        ) : (
                          <EditIcon
                            className={styles.editFieldIcon}
                            onClick={() => toggleEditingState('linkedAccounts')}
                          />
                        )}
                      </div>
                    </div>

                    <div className={styles.bodyDetailsAutoComplete}>
                      <div className={styles.bodyItem}>Brand:</div>
                      {editingStates.linkedAccounts ? (
                        <>
                          <CreatableSelectField
                            className={styles.autocompleteField}
                            name="brandOptionValue"
                            isAsync
                            loadOptions={loadBrandOptions}
                            options={[]}
                            onAddValue={handleOpenNewBrandModal}
                            getOptionLabel={(option: any) => option?.label || option || ''}
                            getOptionValue={(option: any) => option?.value || option || ''}
                          />
                        </>
                      ) : (
                        <LinkedAccountItem
                          type="brand"
                          salesforceBaseUrl={newsItem.salesforceBaseUrl}
                          brandInfo={newsItem.brandFromSalesforce}
                        />
                      )}
                    </div>

                    <div className={styles.bodyDetailsAutoComplete}>
                      <div className={styles.bodyItem}>Other Account(s):</div>
                      {editingStates.linkedAccounts ? (
                        <LinkedAccountInput
                          handleAddNewOtherAccount={handleAddNewOtherAccount}
                          newsItem={newsItem}
                          handleDeleteLinkedAccount={handleDeleteLinkedAccount}
                          loadBrandOptions={loadBrandOptions}
                        />
                      ) : (
                        <div>
                          {newsItem.linkedAccounts.map((linkedAccount, index) => (
                            <span key={index}>
                              <LinkedAccountItem
                                type="other"
                                salesforceBaseUrl={newsItem.salesforceBaseUrl}
                                brandInfo={linkedAccount.brandInfo}
                              />
                              {index < newsItem.linkedAccounts.length - 1 && ', '}
                            </span>
                          ))}
                        </div>
                      )}
                    </div>
                  </Box>
                </form>
              )
            }}
          />
        </div>
        {newsItem?.type === NewsTags.Fundraise && (
          <div className={styles.bodyContainer}>
            <Form
              onSubmit={handleSaveFundraise}
              initialValues={newsItem}
              render={({ handleSubmit }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Box className={styles.fieldsContainer}>
                      <div className={styles.bodyTitle}>
                        <div className={styles.bodyTitleName}>Fundraise</div>
                        <div className={styles.bodyTitleEdit}>
                          {editingStates.fundraise ? (
                            <Button color="primary" variant="contained" small type="submit">
                              Save
                            </Button>
                          ) : (
                            <EditIcon
                              className={styles.editFieldIcon}
                              onClick={() => toggleEditingState('fundraise')}
                            />
                          )}
                        </div>
                      </div>
                      <div className={styles.bodyDetails}>
                        <div className={styles.bodyItem}>Investor(s):</div>
                        {editingStates.fundraise ? (
                          <LinkedAccountInput
                            handleAddNewOtherAccount={handleAddNewOtherAccount}
                            newsItem={newsItem}
                            handleDeleteLinkedAccount={handleDeleteLinkedAccount}
                            loadBrandOptions={loadBrandOptions}
                            isFundraise
                          />
                        ) : (
                          investors.map((investor, index) => (
                            <span key={index}>
                              <LinkedAccountItem
                                type="other"
                                salesforceBaseUrl={newsItem.salesforceBaseUrl}
                                brandInfo={investor.brandInfo}
                              />
                              {index < investors.length - 1 && ', '}
                            </span>
                          ))
                        )}
                      </div>

                      <div className={styles.bodyDetails}>
                        <div className={styles.bodyItem}>Amount:</div>
                        {editingStates.fundraise ? (
                          <CurrencyField name="amount" className={styles.attributeEditing} />
                        ) : (
                          `$${formatPrice(newsItem?.amount)}`
                        )}
                      </div>

                      <div className={styles.bodyDetails}>
                        <div className={styles.bodyItem}>Round:</div>
                        {editingStates.fundraise ? (
                          <Field
                            name="round"
                            render={({ input }) => (
                              <SelectField
                                {...input}
                                className={styles.selectField}
                                variant="outlined"
                                withClear
                                options={Object.values(FundraiseRounds).map((item) => {
                                  return { label: item, value: item }
                                })}
                              />
                            )}
                          />
                        ) : (
                          newsItem?.round
                        )}
                      </div>
                    </Box>
                  </form>
                )
              }}
            />
          </div>
        )}
        <div className={styles.bodyContainer}>
          <Form
            onSubmit={handleSaveSource}
            initialValues={newsItem}
            render={({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Box className={styles.fieldsContainer}>
                    <div className={styles.bodyTitle}>
                      <div className={styles.bodyTitleName}>Source</div>
                      <div className={styles.bodyTitleEdit}>
                        {editingStates.source ? (
                          <Button color="primary" variant="contained" small type="submit">
                            Save
                          </Button>
                        ) : (
                          <EditIcon
                            className={styles.editFieldIcon}
                            onClick={() => toggleEditingState('source')}
                          />
                        )}
                      </div>
                    </div>

                    <div className={styles.bodyDetails}>
                      <div className={styles.bodyItem}>News Source:</div>{' '}
                      {EMAIL_NEWSLETTER_MAPPING?.[newsItem.newsfeedEmail?.email] || ''}
                    </div>

                    <div className={styles.bodyDetails}>
                      <div className={styles.bodyItem}>Link:</div>
                      {editingStates.source ? (
                        <Field
                          name="link"
                          render={({ input }) => (
                            <TextField {...input} className={styles.attributeEditing} multiline />
                          )}
                        />
                      ) : (
                        <a
                          className={styles.bodyItemLink}
                          href={newsItem?.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {newsItem?.link}
                        </a>
                      )}
                    </div>
                  </Box>
                </form>
              )
            }}
          />
        </div>
      </div>

      {modalStates.isEditProfilePicModalOpen && (
        <UpdateBrandImageModal
          newsItem={newsItem}
          setIsEditProfilePicModalOpen={() =>
            setModalStates((prevState) => ({
              ...prevState,
              isEditProfilePicModalOpen: false,
            }))
          }
        />
      )}

      {modalStates.isNewBrandModalOpen && (
        <Modal
          open={modalStates.isNewBrandModalOpen}
          onCancel={handleCloseNewBrandModal}
          title="Create Brand"
          classes={{ root: styles.addBrandModalRoot, body: styles.modalBody }}
        >
          <Form
            onSubmit={handleCreateNewBrand}
            validate={validate}
            render={({ invalid, handleSubmit, dirty }) => (
              <form onSubmit={handleSubmit}>
                <Box display="flex" flexDirection="column" gap={3}>
                  <Box flex={1}>
                    <InputLabel
                      htmlFor="type"
                      className={cn(genericSs.textLeft, styles.newBrandLabel)}
                    >
                      Paste a valid Salesforce URL
                    </InputLabel>
                    <TextField
                      className={styles.newBrandField}
                      name="salesforceLink"
                      placeholder="Salesforce Link"
                      multiline
                    />
                    {newBrandError && <span>{newBrandError}</span>}
                  </Box>
                </Box>

                <Box display="flex" flexDirection="column" mt={2}>
                  <ModalButton
                    isLoading={isSaving}
                    color="primary"
                    small={false}
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={!dirty || invalid}
                  >
                    Create
                  </ModalButton>
                </Box>
              </form>
            )}
          />
        </Modal>
      )}

      {modalStates.isDeleteModalOpen && (
        <WarningModal
          warningMessage="Are you sure you want to delete this news item?"
          onConfirm={handleDeleteNewsConfirm}
          onCancel={handleToggleDeleteModal}
          confirmText="Delete"
          cancelText="Cancel"
          isLoading={isSaving}
        />
      )}
    </div>
  ) : (
    <TableLoader columnsCount={1} rowsCount={1} height={90} />
  )
}

export default MarketNewsDetails

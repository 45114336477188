import React, { useState } from 'react'

import styles from './MarketNewsPage.module.scss'

import Card from '../../components/Common/Card'
import MarketNewsTable from '../../components/MarketNewsTable'
import MarketNewsDetails from '../../components/MarketNewsDetails'
import { INewsfeed } from '@common/interfaces/newsfeed'

const MarketNewsPage = () => {
  const [currentNewsItem, setCurrentNewsItem] = useState<INewsfeed | null>(null)

  return (
    <div>
      <Card
        noHeaderMargin
        noPadding
        className={styles.cardContainer}
        classes={{
          root: styles.card,
        }}
      >
        <MarketNewsTable
          setCurrentNewsItem={setCurrentNewsItem}
          currentNewsItem={currentNewsItem}
        />

        {currentNewsItem && (
          <MarketNewsDetails
            newsItemId={currentNewsItem.id}
            setCurrentNewsItem={setCurrentNewsItem}
          />
        )}
      </Card>
    </div>
  )
}

export default MarketNewsPage

import { IApi } from '../../api'
import { createRequestTypes } from '../../helpers/helpers'
import { CommonPromiseAction } from '../types'
export const prefix = 'newsfeed'

export const [LIST_NEWSFEED_REQUEST, LIST_NEWSFEED_SUCCESS, LIST_NEWSFEED_FAILURE] =
  createRequestTypes(prefix, 'LIST_NEWSFEED')

export const listNewsfeed: CommonPromiseAction = (params: object) => ({
  type: [LIST_NEWSFEED_REQUEST, LIST_NEWSFEED_SUCCESS, LIST_NEWSFEED_FAILURE],
  promise: (api: IApi) => api.newsfeed.listNewsfeed(params),
  params,
})

export const [SHOW_NEWSFEED_REQUEST, SHOW_NEWSFEED_SUCCESS, SHOW_NEWSFEED_FAILURE] =
  createRequestTypes(prefix, 'SHOW_NEWSFEED')

export const showNewsfeed: CommonPromiseAction = (id: string, params?: object) => ({
  type: [SHOW_NEWSFEED_REQUEST, SHOW_NEWSFEED_SUCCESS, SHOW_NEWSFEED_FAILURE],
  promise: (api: IApi) => api.newsfeed.showNewsfeed(id),
  params,
})

export const [DELETE_NEWSFEED_REQUEST, DELETE_NEWSFEED_SUCCESS, DELETE_NEWSFEED_FAILURE] =
  createRequestTypes(prefix, 'DELETE_NEWSFEED')

export const deleteNews: CommonPromiseAction = (id: string, params?: object) => ({
  type: [DELETE_NEWSFEED_REQUEST, DELETE_NEWSFEED_SUCCESS, DELETE_NEWSFEED_FAILURE],
  promise: (api: IApi) => api.newsfeed.deleteNews(id),
  params,
})

export const [
  LIST_CLIENT_NEWSFEED_REQUEST,
  LIST_CLIENT_NEWSFEED_SUCCESS,
  LIST_CLIENT_NEWSFEED_FAILURE,
] = createRequestTypes(prefix, 'LIST_CLIENT_NEWSFEED')

export const listClientNewsfeed: CommonPromiseAction = () => ({
  type: [LIST_CLIENT_NEWSFEED_REQUEST, LIST_CLIENT_NEWSFEED_SUCCESS, LIST_CLIENT_NEWSFEED_FAILURE],
  promise: (api: IApi) => api.newsfeed.listClientNewsfeed(),
})

export const [LIST_BRAND_OPTIONS_REQUEST, LIST_BRAND_OPTIONS_SUCCESS, LIST_BRAND_OPTIONS_FAILURE] =
  createRequestTypes(prefix, 'LIST_BRAND_OPTIONS')

export const listBrandOptions: CommonPromiseAction = (params: object) => ({
  type: [LIST_BRAND_OPTIONS_REQUEST, LIST_BRAND_OPTIONS_SUCCESS, LIST_BRAND_OPTIONS_FAILURE],
  promise: (api: IApi) => api.newsfeed.listBrandOptions(params),
})

export const CAPTURE_CLICK_REQUEST = `${prefix}/CAPTURE_CLICK_REQUEST`
export const CAPTURE_CLICK_SUCCESS = `${prefix}/CAPTURE_CLICK_SUCCESS`
export const CAPTURE_CLICK_FAILURE = `${prefix}/CAPTURE_CLICK_FAILURE`

export const captureClick: CommonPromiseAction = (id: string) => ({
  type: [CAPTURE_CLICK_REQUEST, CAPTURE_CLICK_SUCCESS, CAPTURE_CLICK_FAILURE],
  promise: (api: IApi) => api.newsfeed.capture(id),
  id,
})

export const [UPDATE_NEWSFEED_REQUEST, UPDATE_NEWSFEED_SUCCESS, UPDATE_NEWSFEED_FAILURE] =
  createRequestTypes(prefix, 'UPDATE_NEWSFEED')

export const updateNewsfeed: CommonPromiseAction = (id: string, params: object) => ({
  type: [UPDATE_NEWSFEED_REQUEST, UPDATE_NEWSFEED_SUCCESS, UPDATE_NEWSFEED_FAILURE],
  promise: (api: IApi) => api.newsfeed.updateNewsfeed(id, params),
  params,
})

export const [UPDATE_BRAND_REQUEST, UPDATE_BRAND_SUCCESS, UPDATE_BRAND_FAILURE] =
  createRequestTypes(prefix, 'UPDATE_BRAND')

export const updateBrand: CommonPromiseAction = (id: string, data: object) => ({
  type: [UPDATE_BRAND_REQUEST, UPDATE_BRAND_SUCCESS, UPDATE_BRAND_FAILURE],
  promise: (api: IApi) => api.newsfeed.updateBrand(id, data),
})

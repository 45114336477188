import React, { useCallback, useState } from 'react'
import MuiAutocomplete from '@mui/material/Autocomplete'
import MuiTextField from '@mui/material/TextField'
import { AutocompleteProps } from 'mui-rff'
import cn from 'classnames'

import styles from './TaggingField.module.scss'

interface OptionType {
  label?: string
  value?: string
  inputValue?: string
  type?: string
  isNew?: boolean
}

interface IProps extends Partial<AutocompleteProps<OptionType, false, false, false>> {
  name?: string
  style?: object
  placeholder?: string
  onChange?: (event: object, newValue: object) => void
  disabledClearable?: boolean
  leftIcon?: React.ReactNode
  height?: 'small' | 'medium'
  isAsync?: boolean
  loadOptions?: (value: string) => Promise<OptionType[]>
  isFreeSolo?: boolean
  renderTags?: (value: OptionType[], getTagProps: (params: any) => any) => React.ReactNode
  limitTags?: number
  disabled?: boolean
  onOpen?: () => void
  limtDrowdown: boolean
}

const TaggingField = ({
  className,
  isFreeSolo,
  options,
  value,
  placeholder,
  renderTags,
  onChange,
  filterOptions,
  onInputChange,
  limitTags,
  disabled = false,
  onOpen,
  limtDrowdown,
  isAsync,
  loadOptions,
}: IProps) => {
  const [asyncOptions, setAsyncOptions] = useState<OptionType[]>([])

  const handleInputChange = useCallback(
    async (
      event: React.SyntheticEvent,
      inputValue: string,
      reason: 'input' | 'reset' | 'clear',
    ) => {
      if (!isAsync) {
        onInputChange(event, inputValue, reason)
      }
      if (reason === 'input' && inputValue.length > 1) {
        const loadedOptions = await loadOptions(inputValue)
        setAsyncOptions(loadedOptions)
      } else {
        setAsyncOptions([])
      }
    },
    [isAsync, loadOptions, onInputChange],
  )

  return (
    <MuiAutocomplete
      disableClearable
      renderTags={renderTags}
      options={isAsync ? asyncOptions : options}
      forcePopupIcon={false}
      className={cn(styles.tagsInput, className)}
      ChipProps={{
        className: styles.chip,
      }}
      onChange={onChange}
      multiple
      disableCloseOnSelect
      isOptionEqualToValue={(option: any, value: any) =>
        option?.value === (value && value?.value ? value.value : value)
      }
      limitTags={limitTags}
      classes={{
        inputRoot: styles.input,
        popper: cn(styles.popper, { [styles.popperSmall]: limtDrowdown }),
        focused: styles.focused,
      }}
      renderInput={(params) => (
        <MuiTextField
          fullWidth
          {...params}
          placeholder={placeholder}
          variant="standard"
          InputProps={{
            ...params.InputProps,
            classes: {
              root: styles.inputRoot,
              focused: styles.focusedInput,
            },
            disableUnderline: true,
          }}
        />
      )}
      value={value as OptionType[]}
      freeSolo={isFreeSolo}
      filterOptions={filterOptions}
      onInputChange={handleInputChange}
      // @ts-ignore
      getOptionValue={(option) => option.value}
      disabled={disabled}
      onOpen={onOpen}
    />
  )
}

export default TaggingField

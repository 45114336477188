import { IClientInfo } from './client'
import { IEntityInfo } from './entityInfo'
import { ISalesforceProspectInfo } from './prospects'

export interface INewsfeedDataSheet {
  industry: string
  description: string
  recordDate: string | null
  link: string
  clientName: string
  salesforceId: string
  referralClientName: string
  referralSalesforceId: string
  wholesaler: string
  isBankruptcy: boolean
  isDistribution: boolean
  isFundraise: boolean
  isLawsuit: boolean
  isExit: boolean
  isNewFund: boolean
  amountRaised: string
  roundType: string
  isPublishing: boolean
  owner: string
  newAccountName: string
  isLeadingInvestor: boolean
}

export interface IClientNewsfeed {
  id: string
  createdAt: string
  newsfeedHeadlines: INewsfeed
  type: string
}

export interface INewsfeedEmails {
  id: string
  email: string
  recordDate: string
}

export interface IBrand {
  id: string
  brandName: string
  salesforceId: string
  clientInfo: IClientInfo
  salesforceProspectInfo: ISalesforceProspectInfo
  entityInfo: IEntityInfo
  salesforceType: string
  brandIcon: string
}

export interface ILinkedAccount {
  id: string
  salesforceId: string | null
  type: string
  brandInfo: IBrand | null
}

export interface INewsfeed {
  id: string
  headline: string
  link: string
  relevanceScore: number | null
  type: string | null
  brand: string | null
  round: string | null
  amount: number | null
  industry: string | null
  isProcessed: boolean | null
  isUploaded: boolean
  createdAt: string
  updatedAt: string
  salesforceId: string | null
  isUrlTransformed: boolean
  clientNewsFeed: IClientNewsfeed[]
  clientName?: string
  clientInfo?: IClientInfo
  entityName?: string
  entityInfo?: IEntityInfo
  recordDate: string
  description: string
  headlineSummary: string
  newsfeedEmail: INewsfeedEmails
  brandFromSalesforce: IBrand
  isClient: boolean
  isProspect: boolean
  isEntity: boolean
  brandOptionValue?: { value: string; label: string }
  salesforceBaseUrl: string
  linkedAccounts: ILinkedAccount[]
}

export interface INewsfeedData {
  data: INewsfeed[]
  totals: {
    totalItems: number
  }
}

export interface INewsfeedItemData {
  data: INewsfeed
}

export interface IBrandOptions {
  brandsOptions: { label: string; value: string }[]
}

export interface IClientNewsfeedData {
  data: IClientNewsfeed[]
}

export enum NewsTags {
  Lawsuit = 'Lawsuit',
  Fundraise = 'Fundraise',
  Other = 'Other',
  Bankruptcy = 'Bankruptcy',
  Distribution = 'Distribution',
  NewFund = 'New Fund',
  Exit = 'Exit',
  Client = 'Client',
  Prospect = 'Prospect',
}

export enum FundraiseRounds {
  Seed = 'Seed',
  SeriesA = 'Series A',
  SeriesB = 'Series B',
  SeriesC = 'Series C',
  SeriesD = 'Series D',
  SeriesE = 'Series E',
  Other = 'Other',
}

export const TAG_COLOR_MAPPING = {
  [NewsTags.Lawsuit]: { backgroundColor: '#FBECC6', color: '#7C6C42' },
  [NewsTags.Bankruptcy]: { backgroundColor: '#DBE6DB', color: '#578357' },
  [NewsTags.Other]: { backgroundColor: '#F0ECEC', color: '#734545' },
  [NewsTags.NewFund]: { backgroundColor: '#BEC6EB', color: '#535E91' },
  [NewsTags.Exit]: { backgroundColor: '#EF8A84', color: '#761818' },
  [NewsTags.Distribution]: { backgroundColor: '#CDDEEF', color: '#476C91' },
  [NewsTags.Fundraise]: { backgroundColor: '#FCF2F3', color: '#7C393F' },
  [NewsTags.Client]: { backgroundColor: '#DFE1E0', color: '#3B6D54' },
  [NewsTags.Prospect]: { backgroundColor: '#D5DEFD', color: '#455EB2' },
}

export const TAG_OPTIONS = Object.values(NewsTags)
  .filter((item) => ![NewsTags.Client, NewsTags.Prospect].includes(item))
  .map((item) => {
    return { label: item, value: item }
  })

export const EMAIL_NEWSLETTER_MAPPING = {
  'expresscheckout@mail.beehiiv.com': 'Express Checkout',
  'mike@whipstitchcapital.com': 'Whipstitch Capital',
  'news@thehustle.co': 'The Hustle',
  'newsletter@divenewsletter.com': 'Dive Newsletter',
  'stitch@whipstitchcapital.com': 'Whipstitch Capital',
  'thisweekincpg@mail.beehiiv.com': 'This Week in CPG',
}

export interface IUpdateData {
  id: string
  headline: string
  headlineSummary: string
  type: string
  link: string
  investor: string
  amount: number
  round: string
  linkedAccounts: string
  salesforceId: string
  salesforceLink: string
  otherAccountId: string
  linkedAccountId: string
  isFundraise: boolean
}

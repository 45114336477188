import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import MarketNewsTable from './MarketNewsTable'

import { newsfeedData } from '../../redux/newsfeed/selectors'
import { listNewsfeed, listBrandOptions } from '../../redux/newsfeed/actions'

const selector = createStructuredSelector({
  newsfeedData,
})
const actions = {
  listNewsfeed,
  listBrandOptions,
}

export default connect(selector, actions)(MarketNewsTable)

import React, { useCallback, useEffect, useMemo } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import useTable from '../../hooks/useTable'
import styles from './MarketNewsTable.module.scss'
import TableLoader from '../Common/TableLoader'
import { INewsfeedData, INewsfeed } from '@common/interfaces/newsfeed'
import { ILoadingData } from '../../redux/types'
import MarketNewsItem from './MarketNewsItem'
import TableContainer from '../Common/TableContainer'
import FilterContainer from '../Filters/FilterContainer'
import { Form } from 'react-final-form'
import Table from '../Common/Table'
import TableBody from '../Common/TableBody'
import { NEWS_FEED_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'

interface IProps {
  newsfeedData: ILoadingData<INewsfeedData>
  listNewsfeed: (data: object) => void
  setCurrentNewsItem: (item: INewsfeed | null) => void
  currentNewsItem: INewsfeed
  listBrandOptions: (data: object) => Promise<{ data: any[] }>
}

const filtersValidate = buildFiltersValidateSchema(NEWS_FEED_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(NEWS_FEED_LIST_FILTERS_CONFIG)

const MarketNewsTable = ({
  newsfeedData,
  listNewsfeed,
  setCurrentNewsItem,
  currentNewsItem,
  listBrandOptions,
}: IProps) => {
  const { data: newsfeed, totalItems } = useMemo(
    () => ({
      data: newsfeedData?.data?.data,
      isLoading: newsfeedData?.isLoading,
      totalItems: newsfeedData?.data?.totals?.totalItems,
    }),
    [newsfeedData],
  )

  const { filters, handleFiltersChange, quickFilter, handleQuickFilterChange } = useTable({
    tableId: 'newsfeedTable',
    filtersDefaults,
    sortDefault: {
      field: 'createdAt',
      direction: 'DESC',
    },
  })

  const handleListNewsfeed = useCallback(
    (params?: object) => {
      listNewsfeed({
        perPage: PER_PAGE,
        filters,
        ...params,
      })
    },
    [listNewsfeed, filters],
  )

  useEffect(() => {
    handleListNewsfeed()
  }, [handleListNewsfeed])

  const loadMore = useCallback(() => {
    handleListNewsfeed({
      loadMore: true,
      page: Math.ceil(newsfeedData.data.data.length / PER_PAGE),
    })
  }, [handleListNewsfeed, newsfeedData])

  const handleNewsHeadlineClick = useCallback(
    (item: INewsfeed) => {
      setCurrentNewsItem(item)
    },
    [setCurrentNewsItem],
  )

  useEffect(() => {
    const isCurrentItemInNewsfeed = newsfeed?.some((item) => item.id === currentNewsItem?.id)

    if (!newsfeed?.length) {
      setCurrentNewsItem(null)
    } else if (!isCurrentItemInNewsfeed) {
      setCurrentNewsItem(newsfeed[0])
    }
  }, [newsfeed, setCurrentNewsItem, currentNewsItem?.id])

  const loadOptions = useCallback(
    async (search: string) => {
      const res = await listBrandOptions({ search })
      return res.data.map(({ label }) => ({
        value: label,
        label,
      }))
    },
    [listBrandOptions],
  )

  const filtersConfig = useMemo(
    () =>
      NEWS_FEED_LIST_FILTERS_CONFIG.map((item) => ({
        ...item,
        options: !['brand', 'referral_source'].includes(item.field) ? item.options : undefined,
        loadOptions: ['brand', 'referral_source'].includes(item.field) ? loadOptions : undefined,
      })),
    [loadOptions],
  )

  return (
    <>
      <div className={styles.newsContainer}>
        <TableContainer className={styles.table}>
          <Form
            validate={filtersValidate}
            onSubmit={handleFiltersChange}
            initialValues={filters}
            mutators={{
              setFieldData: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value)
              },
            }}
            render={({ values, handleSubmit, form: { mutators } }) => (
              <div className={styles.filterContainer}>
                <FilterContainer
                  filters={filtersConfig}
                  handleSubmit={handleSubmit}
                  mutators={mutators}
                  values={values}
                  appliedFilters={filters}
                  appliedQuickFilter={quickFilter}
                  handleAppliedQuickFilterChange={handleQuickFilterChange}
                />
              </div>
            )}
          />
          <Table
            classes={{
              root: styles.tableRoot,
            }}
          >
            {newsfeed?.length ? (
              <TableBody className={styles.container} id="marketNewsTable">
                <InfiniteScroll
                  dataLength={newsfeed?.length}
                  className={styles.infiniteScroll}
                  next={loadMore}
                  hasMore={newsfeed?.length < totalItems}
                  loader={<TableLoader columnsCount={1} rowsCount={1} />}
                  scrollableTarget="marketNewsTable"
                >
                  {newsfeed?.map((item) => (
                    <div
                      className={styles.newsItemContainer}
                      onClick={() => handleNewsHeadlineClick(item)}
                    >
                      <MarketNewsItem
                        isCurrentItem={currentNewsItem?.id === item.id}
                        key={item.id}
                        item={item}
                      />
                    </div>
                  ))}
                </InfiniteScroll>
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>
      </div>
    </>
  )
}

export default MarketNewsTable
